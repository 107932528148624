@use '@angular/material' as mat;
@import "../node_modules/@angular/material/theming";

@include mat.core();
@include mat.strong-focus-indicators();

$bv-brand: (
    100: #dde6f3,
    700: #38649d,
    800: #3f55a5,
    contrast: (
      A100: $black-87-opacity,
      A200: $black-87-opacity,
      A400: $black-87-opacity,
      A600: $black-87-opacity
    )
);

$my-primary: mat-palette($bv-brand, 800);
$my-accent: mat.define-palette($mat-light-green, 700);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
 )
));

@include mat.all-component-themes($my-theme);
@include mat.strong-focus-indicators-theme($my-theme);

.density-lower {
  .density-mat-form-3, .density-mat-form-2 {
    @include mat.form-field-density(-5);
    @include mat.icon-button-density(-5);
    @include mat.datepicker-density(-5);
  }
}

.background-white > .mat-mdc-text-field-wrapper{
  background-color: #fff;
  border-radius: 5px;
}

.cdk-overlay-pane > .mat-mdc-menu-panel {
  max-width: 400px !important;
  border-radius: 10px;
}

.mat-ripple {
  display: none;
}

.mat-mdc-chip-primary-focus-indicator {
  display: none;
}

.mat-mdc-dialog-surface {
  border-radius: 20px !important;
}

.mdc-evolution-chip-set__chips {
  max-width: 100% !important;
}



.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  overflow: hidden !important;
}


.mat-mdc-snack-bar-container {
  &.success {
    --mdc-snackbar-container-color: #5dd55d;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #000;
  }
  &.error {
    --mdc-snackbar-container-color: #f44336;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #000;
  }
}
