@import "src/variables.scss";

#chat-robbu {
  z-index: 2 !important;
}

#chat-robbu>#cr-trigger {
  margin-left: auto;
  width: 72px;
  height: 72px;
  position: relative;
  bottom: 15px;
  right: 5px;
  border-radius: 50px;
}

#chat-robbu>#cr-whatsapp-trigger {
  margin-left: auto !important;
  width: 72px !important;
  height: 72px !important;
  position: relative !important;
  bottom: 0px !important;
  right: 5px !important;
  border-radius: 50px !important;
}

#chat-robbu #cr-trigger {
  background: #1E6FB1 !important;
  box-shadow: none;
}

.cr-trigger-icon {
  width: 65px !important;
}

.cr-image {
  width: 50px !important;
  height: 28px !important;
}


@media screen and (max-width: $md){
  #chat-robbu>#cr-whatsapp-trigger {
    width: 40px !important;
    height: 40px !important;
    bottom: -15px !important;
    right: -10px !important;
    font-size: 22px !important;
  }
}


/* for mobile */
@media screen and (max-width: $md){
  #chat-robbu>#cr-trigger {
    width: 40px !important;
    height: 40px !important;
    bottom: -5px !important;
    right: -10px !important;
    font-size: 22px !important;
  }

  .cr-trigger-icon  {
    width: 37px !important;
  }
}