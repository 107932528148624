@use '@angular/material' as mat;
@import "./variables.scss";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; background-color: #f5f5f5; }

@font-face {
    font-family: Estricta;
    src: url(assets/fonts/Estricta-Regular.ttf) format("truetype");
}

.estricta {
    font-family: Estricta;
}

.bolder {
    font-weight: 600;
}

.bold {
    font-weight: 500;
}

.backdrop-blur {
  background: #0001;
  padding: 5px 32px!important;
  border-radius: 6px !important;
  backdrop-filter: blur(100px);
  box-shadow: 1px 1px 10px 2px #0002;
}

.white-bolder {
  color: #fff !important;
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.grey {
    color: #8c8c8c;
}

.title {
    font-size: 30px;
    text-transform: uppercase
}

.uppercase {
    text-transform: uppercase;
}

.full {
    margin: calc(-1 * $padding-y-xl) calc(-1 * $padding-x-xl);
}

.form-field {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 7px;
    font-size: 15px;
}

/* Show the default arrow when a matInput is readonly*/
input[matInput][readonly] {
  cursor: default;
}

.mat-button-focus-overlay {
    background-color:transparent !important;
   }

@media screen and ((min-width:$xm) or (max-width:$xm)) and (max-width:$sm){
    .full {
        margin: calc(-1 * $padding-y-xm) calc(-1 * $padding-x-xm);
    }

    .title {
        font-size: 20px;
        text-transform: uppercase
    }
}

@media screen and (min-width:$sm) and (max-width:$md){
    .full {
        margin: calc(-1 * $padding-y-sm) calc(-1 * $padding-x-sm);
    }

    .title {
        font-size: 20px;
        text-transform: uppercase
    }
}

@media screen and (min-width:$md) and (max-width:$lg){
    .full {
        margin: calc(-1 * $padding-y-md) calc(-1 * $padding-x-md);
    }
}

@media screen and (min-width:$lg) and (max-width:$xl){
    .full {
        margin: calc(-1 * $padding-y-lg) calc(-1 * $padding-x-lg);
    }
}

.custom-modalbox > mat-dialog-container {
    background-color: transparent !important;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #38649d #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #38649d;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

  .mat-expansion-panel-header.mat-focus-indicator:focus::before {
    display: none !important;
  }
